import React from "react"
import { styled } from "@mui/system"
import CompanyCard from "./companyCard"

const Wrapper = styled("div")({
  width: "100%",
  maxWidth: "1280px",
  display: "grid",
  gridTemplateColumns: "1fr 1fr 1fr",
  rowGap: "69px",
  columnGap: "46px",
  height: "100%",
  margin: "0 auto",
  "@media (max-width: 1336px)": {
    padding: "0 28px",
  },
  "@media (max-width: 1024px)": {
    gridTemplateColumns: "1fr 1fr",
    padding: "0 28px",
    rowGap: "50px",
    columnGap: "30px",
  },
  "@media (max-width: 576px)": {
    padding: "0 20px",
    gridTemplateColumns: "1fr",
    rowGap: "66px",
  },
})

const PortfolioWidget = ({ data }) => {
  const clean_data = data.edges.map(item => {
    var clean_node = {
      companyName: item.node.name,
      Logo: item.node.logo.localFile.childImageSharp?.gatsbyImageData ?? item.node.logo.localFile.publicURL,
      extension: item.node.logo.localFile.extension,
      previewImg: item.node.background_image.localFile.childImageSharp.gatsbyImageData,
      mottoText: item.node.motto,
      color: item.node.color,
    }
    return clean_node
  })


  return (
    <Wrapper>
        {clean_data.map(card => (
            <CompanyCard company={card} key={card.companyName.toLowerCase()}/>
        ))}
    </Wrapper>
  )
}

export default PortfolioWidget
