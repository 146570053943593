import * as React from "react"
import GridLines from "../components/gridLines"
import Layout from "../components/layout"
import Seo from "../components/seo"
import PortfolioHeader from "../components/portfolioPage/portfolioHeader"
import PortfolioWidget from "../components/portfolioPage/portfolioWidget"
import { graphql } from "gatsby"


const PortfolioPage = ({ data }) => {
  return (
    <Layout headerDark={false}>
      <Seo title="Portfolio" description={`We are empowered to find and fund companies with the most potent potential with the ability to bridge the gap between vision and execution to produce a truly landmark result.`}/>
      <PortfolioHeader />
      <GridLines
        styles={{
          height: 174,
          heightMob: 60,
          marginTopMob: 17,
          marginBottom: 28,
          marginBottomMob: 25,
          color: "#F8F8F8",
        }}
      />
      <PortfolioWidget data={data.allStrapiCompany} />
      <GridLines
        styles={{
          height: 87,
          heightMob: 30,
          marginTop: 69,
          marginTopMob: 106,
          marginBottomMob: 0,
          color: "#F8F8F8",
        }}
      />
    </Layout>
  )
}

export default PortfolioPage


export const query = graphql`
  query PortFolioPageQuery {
    allStrapiCompany(sort: { fields: rank, order: ASC }) {
      edges {
        node {
          rank
          color
          motto
          name
          logo {
            localFile {
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED, quality: 100)
              }
              publicURL
              extension
            }
          }
          background_image {
            localFile {
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED, quality: 100)
              }
            }
          }
        }
      }
    }
  }
  `