import React from "react"
import { styled } from "@mui/system"
import { Typography } from "@mui/material"
import { GatsbyImage } from "gatsby-plugin-image"
import { Link } from "gatsby"

const Wrapper = styled("div")({
  width: "100%",
  height: "100%",
  maxWidth: "396px",
  display: "flex",
  flexDirection: "column",
  alignContent: "center",
  alignItems: "center",
  justifyContent: "flex-start",
  "@media (max-width:1024px)": {
    maxWidth: "100vw",
  },
})

const LogoWrapper = styled("div")({
  width: "100%",
  height: "100%",
  maxHeight: "52px",
  display: "flex",
  alignItems: "center",
  marginBottom: "32px",
  "@media (max-width:1100px)": {
    maxHeight: "48px",
  },
  "@media (max-width:1024px)": {
    maxHeight: "40px",
    marginBottom: "30px",
  },

  "@media (max-width:900px)": {
    maxHeight: "30px",
  },
  "@media (max-width:577px)": {
    maxHeight: "40px",
    marginBottom: "20px",
  },
  "@media (max-width:400px)": {
    maxHeight: "30px",
  },

  "& hr": {
    width: "auto",
    height: "4px",
    marginBottom: "0",
    marginLeft: "20px",
    flexGrow: 3,
  },
  "@media (max-width:1280px)": {
    "& hr": {
      height: "3px",
    },
  },
  "@media(max-width: 577px)": {

    "& hr": {
      height: "3px",
      marginLeft: "11px",
      // marginBottom: "6px",
    },
  },
})

const MottoText = styled(Typography)(({ theme }) => ({
  fontFamily: "Merriweather",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: "1.22rem",
  lineHeight: "190%",
  display: "block",
  color: theme.palette.secondary.main,
  textAlign: "left",
  width: "100%",
  "@media (max-width:1280px)": {
    fontSize: "1rem",
  },
  "@media(max-width: 1025px)": {
    fontSize: "0.8rem",
  },
  "@media(max-width: 577px)": {
    maxWidth: "95vw",
    fontSize: "1rem",
  },
}))

const ImageLogo = styled("img")({
  maxHeight: "52px",
  height: "auto",
  maxWidth: "65%",
  objectFit: "contain",
  marginBottom: 0,
})

const GatsbyImageLogo = styled(GatsbyImage)({
  maxWidth: "75%",
  "& img": {
    width: "auto",
    maxHeight: "52px",
    "@media (max-width:1100px)": {
      maxHeight: "48px",
    },

    "@media (max-width:1024px)": {
      maxHeight: "40px",
    },

    "@media (max-width:900px)": {
      maxHeight: "30px",
    },
    "@media (max-width:600px)": {
      maxHeight: "40px",
    },
    "@media (max-width:400px)": {
      maxHeight: "30px",
    },
  },

})

const GatsbyImageMain = styled(GatsbyImage)({
  flexShrink: 0,
  // aspectRatio: "1.55/1",
  borderRadius: "2px",
  border: "1px solid #E3E3E3",
  marginBottom: "32px",
  "@media(max-width: 1024px)": {
    marginBottom: "25px",
  },
  "@media(max-width: 577px)": {
    marginBottom: "20px",
  },

})

const CompanyCard = ({ company }) => {
  const link = company.companyName.toLowerCase().replace('.', '-').replace(/ /g,'-').replace(/[^\w-]+/g,'')
  return (
      <Wrapper>
        <Link to={`/` + link} style={{textDecoration: "none", widht: "100%", height: "100%"}}>
          <GatsbyImageMain
              image={company.previewImg}
              alt={`${company.companyName}-img`}
          />
          <LogoWrapper>
            {company.extension === "svg" ? (
                <ImageLogo
                    src={`${company.Logo}`}
                    alt={`${company.companyName}-logo`}
                    loading="lazy"
                />
            ) : (
                <GatsbyImageLogo
                    image={company.Logo}
                    alt={`${company.companyName}-logo`}
                    objectFit="contain"
                    style={{width: "auto", height: "auto"}}
                />
            )}
            <hr style={{background: company.color, alignSelf: "flex-end", marginBottom: 8}} />
          </LogoWrapper>
          <MottoText>{company.mottoText}</MottoText>
        </Link>
      </Wrapper>
  )
}

export default CompanyCard
